exports.components = {
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-chrysalis-js": () => import("./../../../src/pages/chrysalis.js" /* webpackChunkName: "component---src-pages-chrysalis-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-facility-js": () => import("./../../../src/pages/facility.js" /* webpackChunkName: "component---src-pages-facility-js" */),
  "component---src-pages-highschool-js": () => import("./../../../src/pages/highschool.js" /* webpackChunkName: "component---src-pages-highschool-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keyelements-js": () => import("./../../../src/pages/keyelements.js" /* webpackChunkName: "component---src-pages-keyelements-js" */),
  "component---src-pages-montessori-js": () => import("./../../../src/pages/montessori.js" /* webpackChunkName: "component---src-pages-montessori-js" */),
  "component---src-pages-yuvipep-js": () => import("./../../../src/pages/yuvipep.js" /* webpackChunkName: "component---src-pages-yuvipep-js" */)
}

